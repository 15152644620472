import React, { useContext, useEffect, useState } from "react";
import "../../css/Registro.css";
import Calendar from "../../components/Calendario";
import Button from "../../components/Button";
import ButtonBack from "../../components/ButtonBack.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import SelectEmpleados from "../../components/SelectEmpleados";
import {
  getObjetivos,
  getPeriod,
  getPuesto,
  postRegistryArray,
} from "../../utils/peticionesApi";
import Select from "react-select";
import { NewContext } from "../../utils/ContextProvider";

const AgregarRegistro = ({ setModalAgregarRegistro, onRegistroGuardado }) => {
  const { getAlert } = useContext(NewContext);
  const [objetivos, setObjetivos] = useState();
  const [puestos, setPuestos] = useState();
  const [selectedDays, setSelectedDays] = useState([]);
  const [periodos, setPeriodos] = useState();
  const [uuid, setUuid] = useState(null);
  const [idObjetivo, setIdObjetivo] = useState(null);
  const [idPuesto, setIdPuesto] = useState(null);

  useEffect(() => {
    listarObjetivos();
    listarPeriodos();
  }, []);

  const listarObjetivos = async () => {
    try {
      const response = await getObjetivos();
      if (response?.data?.content) {
        const newObjetivo = response.data.content.map((objetivo) => ({
          value: `${objetivo.id}`,
          label: `${objetivo.name}`,
        }));
        setObjetivos(newObjetivo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const listarPeriodos = async () => {
    try {
      const response = await getPeriod();
      const newPeriodo = response.data.map((periodo) => ({
        value: `${periodo.id}`,
        label: `${periodo.name}  - ${periodo.start_time[0] < 10
          ? "0" + periodo.start_time[0]
          : periodo.start_time[0]
          }:${periodo.start_time[1] < 10
            ? "0" + periodo.start_time[1]
            : periodo.start_time[1]
          } / ${periodo.end_time[0] < 10
            ? "0" + periodo.end_time[0]
            : periodo.end_time[0]
          }:${periodo.end_time[1] < 10
            ? "0" + periodo.end_time[1]
            : periodo.end_time[1]
          }`,
      }));
      setPeriodos(newPeriodo);
    } catch (error) {
      console.log(error);
    }
  };

  const listarPuestos = async (id) => {
    try {
      const resultado = await getPuesto(id);
      if (resultado?.data) {
        const newObjetivo = resultado.data.map((puesto) => ({
          value: `${puesto.id}`,
          label: `${puesto.name}`,
        }));
        setPuestos(newObjetivo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeObjetivo = (select) => {
    setIdObjetivo(select.value);
    listarPuestos(select.value);
  };

  const handleChangePuesto = (select) => {
    setIdPuesto(select.value);
  };

  const handleChangePeriodo = (selectedPeriodo) => {
    const { value, label } = selectedPeriodo;
    const [startTime, endTime] = label.split(" - ")[1].split(" / ");

    const updatedDays = selectedDays.map((day) => ({
      ...day,
      startTime: startTime.trim(),
      endTime: endTime.trim(),
    }));

    setSelectedDays(updatedDays);
  };

  const handleTimeChange = (index, field, value) => {
    const updatedDays = [...selectedDays];
    updatedDays[index] = {
      ...updatedDays[index],
      [field]: value,
    };
    setSelectedDays(updatedDays);
  };

  const handleSave = async () => {
    if (!uuid)
      return getAlert({ msj: "El campo empleado es requerido", tipe: "error" });
    if (!idObjetivo)
      return getAlert({ msj: "El campo Objetivo es requerido", tipe: "error" });
    if (!idPuesto)
      return getAlert({ msj: "El campo Puesto es requerido", tipe: "error" });
    const registros = selectedDays.flatMap((dia) => {
      const {
        date,
        startTime,
        endTime,
        horarioCortado,
        startTimeDos,
        endTimeDos,
      } = dia;
      if (!startTime)
        return getAlert({
          msj: "La fecha de ingreso es requerida",
          tipe: "error",
        });
      if (!endTime)
        return getAlert({
          msj: "La fecha de egreso es requerida",
          tipe: "error",
        });
      if (horarioCortado) {
        if (!startTimeDos)
          return getAlert({
            msj: "La fecha de ingreso es requerida",
            tipe: "error",
          });
        if (!endTimeDos)
          return getAlert({
            msj: "La fecha de egreso es requerida",
            tipe: "error",
          });
      }

      const formatDate = (dateStr) => dateStr.split("-").reverse().join("-");

      const addDay = (dateStr) => {
        const dateObj = new Date(dateStr);
        dateObj.setDate(dateObj.getDate() + 1);
        return dateObj
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-");
      };

      if (horarioCortado) {
        const entrada = {
          date: formatDate(date),
          employee_id: uuid,
          objective_id: parseInt(idObjetivo),
          place_id: parseInt(idPuesto),
          shift_id: 1,
          time: `${startTime}:00`,
          type: "I",
        };

        const salida = {
          date: endTime < startTime ? addDay(date) : formatDate(date),
          employee_id: uuid,
          objective_id: parseInt(idObjetivo),
          place_id: parseInt(idPuesto),
          shift_id: 1,
          time: `${endTime}:00`,
          type: "E",
        };
        const entrada2 = {
          date: startTimeDos < endTime ? addDay(date) : formatDate(date),
          employee_id: uuid,
          objective_id: parseInt(idObjetivo),
          place_id: parseInt(idPuesto),
          shift_id: 2,
          time: `${startTimeDos}:00`,
          type: "I",
        };

        const salida2 = {
          date:
            endTimeDos < startTimeDos || startTimeDos < endTime
              ? addDay(date)
              : formatDate(date),
          employee_id: uuid,
          objective_id: parseInt(idObjetivo),
          place_id: parseInt(idPuesto),
          shift_id: 2,
          time: `${endTimeDos}:00`,
          type: "E",
        };
        return [entrada, salida, entrada2, salida2];
      } else {
        const entrada = {
          date: formatDate(date),
          employee_id: uuid,
          objective_id: parseInt(idObjetivo),
          place_id: parseInt(idPuesto),
          shift_id: 4,
          time: `${startTime}:00`,
          type: "I",
        };

        const salida = {
          date: endTime < startTime ? addDay(date) : formatDate(date),
          employee_id: uuid,
          objective_id: parseInt(idObjetivo),
          place_id: parseInt(idPuesto),
          shift_id: 4,
          time: `${endTime}:00`,
          type: "E",
        };
        return [entrada, salida];
      }
    });

    try {
      await postRegistryArray(registros);
      getAlert({ msj: "El registro se guardo correctamente" });
      onRegistroGuardado();
      setModalAgregarRegistro(false);
    } catch (error) {
      const errorMessage =
      error.response?.data?.message || "Ocurrió un error inesperado.";
    getAlert({ msj: errorMessage, tipe: "error" }); // Mostrar el mensaje de error
    console.error(error); // Log para depuración
    }
  };

  return (
    <div className="contenedorRegistro">
      <ButtonBack />
      <p className="titulo">Agregar Registro</p>
      <div className="contenedorFormCalendario">
        <div className="contenedorFormRegistros">
          <div className="contenedorUnoRegistro">
            <div className="contenedorDatosRegistros">
              <p>Nombre</p>
              <SelectEmpleados setUuid={setUuid} />
            </div>
            <div className="contenedorDosRegsitros">
              <div className="contenedorDatosRegistros">
                <p>Objetivo</p>
                <Select
                  options={objetivos}
                  onChange={handleChangeObjetivo}
                  placeholder="Buscar objetivo"
                />
              </div>
              <div className="contenedorDatosRegistros">
                <p>
                  Puesto{" "}
                  <span className="aclaracionSelectRegistro">
                    (Seleccionar objetivo)
                  </span>
                </p>
                <Select
                  className={idObjetivo ? "" : "hidden"}
                  options={puestos}
                  onChange={handleChangePuesto}
                  placeholder="Buscar puesto"
                />
              </div>
              <div className="contenedorDatosRegistros">
                <p>
                  Periodo{" "}
                  <span className="aclaracionSelectRegistro">
                    (Seleccionar Días)
                  </span>
                </p>
                <Select
                  className={selectedDays.length > 0 ? "" : "hidden"}
                  options={periodos}
                  placeholder="Buscar periodo"
                  onChange={handleChangePeriodo}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contenedorModalCalendario">
          <Calendar
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
          />
        </div>
      </div>
      <div className="contenedorTablaAgregarRegistro">
        {selectedDays.length > 0 && (
          <table>
            <thead>
              <tr style={{ backgroundColor: "#fff" }}>
                <th style={{ textAlign: "start" }}>Dia</th>
                <th style={{ textAlign: "start" }}>Periodo</th>
                <th style={{ textAlign: "start" }}>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {selectedDays.map((dia, index) => {
                const isNocturno =
                  dia.endTime < dia.startTime ||
                  dia.endTimeDos < dia.startTimeDos ||
                  dia.endTime > dia.startTimeDos;

                return (
                  <tr key={index} className="itemRegistroPeriodo">
                    <td className="contenedorDiaRegistro">
                      {dia.date.split("-").reverse().join("-")}
                      {isNocturno &
                        (dia.endTime !== "") &
                        (dia.endTimeDos !== "") && (
                          <span className="aclaracionHorarioNocturno"> 1</span>
                        )}
                    </td>
                    <td>
                      <div className="contenedorPeriodo">
                        <input
                          type="time"
                          value={dia.startTime}
                          onChange={(e) =>
                            handleTimeChange(index, "startTime", e.target.value)
                          }
                        />
                        <FontAwesomeIcon icon={faArrowRightLong} />
                        <input
                          type="time"
                          value={dia.endTime}
                          onChange={(e) =>
                            handleTimeChange(index, "endTime", e.target.value)
                          }
                        />
                        {selectedDays[index].horarioCortado ? (
                          <FontAwesomeIcon
                            icon={faMinus}
                            onClick={() =>
                              handleTimeChange(index, "horarioCortado", false)
                            }
                            className="iconEliminarHorarioCortado"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faPlus}
                            onClick={() =>
                              handleTimeChange(index, "horarioCortado", true)
                            }
                            className="iconAgregarPeriodos"
                          />
                        )}

                        <input
                          type="time"
                          value={dia.startTimeDos}
                          onChange={(e) =>
                            handleTimeChange(
                              index,
                              "startTimeDos",
                              e.target.value
                            )
                          }
                          className={`${selectedDays[index].horarioCortado
                            ? ""
                            : "visibility"
                            }`}
                        />
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className={`${selectedDays[index].horarioCortado
                            ? ""
                            : "visibility"
                            }`}
                        />
                        <input
                          type="time"
                          value={dia.endTimeDos}
                          onChange={(e) =>
                            handleTimeChange(
                              index,
                              "endTimeDos",
                              e.target.value
                            )
                          }
                          className={`${selectedDays[index].horarioCortado
                            ? ""
                            : "visibility"
                            }`}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="contenedorBtnGuardarRegistro">
        {selectedDays.length > 0 && (
          <Button onClick={handleSave} msj="Guardar" />
        )}
      </div>
    </div>
  );
};

export default AgregarRegistro;

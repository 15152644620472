import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types"; // Para validar las props

const ButtonBack = ({ onClick, className, children }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(); // Ejecuta la función personalizada si se proporciona
        } else {
            window.history.back(); // Comportamiento predeterminado
        }
    };

    return (
        <button onClick={handleClick} className={`buttonVolverObj ${className}`}>
            <FontAwesomeIcon icon={faCircleLeft} />
            {children || "Volver"} {/* Usa el texto "Volver" por defecto */}
        </button>
    );
};

// Validación de las props
ButtonBack.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
};

ButtonBack.defaultProps = {
    className: "",
    children: null,
};

export default ButtonBack;
import { React, useContext } from "react";
import "../../css/MostrarAsistenciaModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { NewContext } from "../../utils/ContextProvider";
import defaultProfilePicture from '../../image/Logo-jornada.svg';

const MostrarAsistenciaModal = ({ setShowModal, asistencia }) => {
  console.log(asistencia);
  const { getCompany, getId } = useContext(NewContext);
  const ulrPicturePersonal = 'https://www.softnik.cloud/awsProfile/getProfile';
  const id = null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={() => setShowModal(false)}>
          &times;
        </button>
        <h2>Detalle de Asistencia</h2>

        <div className="modal-content">
          {/* Información de asistencia */}
          <div className="info-section">
            <div className="event-container">
              <FontAwesomeIcon icon={faCircleExclamation} className="event-icon" />
              <p><strong>Evento:</strong> {asistencia.type === 'I' ? 'INGRESO' : asistencia.type === 'E' ? 'EGRESO' : 'AUSENTE'}</p>
            </div>
            <p><strong>Nombre:</strong> {asistencia.name}</p>
            <p><strong>Apellido:</strong> {asistencia.lastname}</p>
            <p><strong>Fecha:</strong> {asistencia.date}</p>
            <p><strong>Hora:</strong> {asistencia.registry}</p>
            <p><strong>Empresa:</strong> {asistencia.nameObjective}</p>
            <p><strong>Puesto:</strong> {asistencia.namePlace}</p>
            <p><strong>Ciudad:</strong> {asistencia.cityObjective}</p>
          </div>

          {/* Línea divisoria */}
          <div className="divider"></div>

          {/* Imagen de ingreso */}
          <div className="image-section">
            <p><strong>Imagen de Ingreso</strong></p>
            {getId && (
              <img
                alt="foto perfil"
                className="profile-image"
                src={`${ulrPicturePersonal}?uuid=${id}&company=${getCompany}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultProfilePicture;
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostrarAsistenciaModal;

import React, { useContext, useEffect, useState } from 'react';
import CardHome from '../components/CardHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuildingUser, faClock, faFaceFrownOpen, faUser, faCakeCandles, faChartPie } from '@fortawesome/free-solid-svg-icons';
import MetricsHome from '../components/MetricsHome';
import { getBirdays, getStadisctic, getStadiscticArrival, getStatus } from '../utils/peticionesApi';
import { NewContext } from '../utils/ContextProvider';
import CardArrivals from '../components/CardArrivals';
import CardBirthday from '../components/CardBirthday';
import LoadingOverlay from '../components/LoadingOverlay';
import OptionComponenteRegister from '../forms/Home/OptionComponentHome';

const Home = () => {
  const { selectedEmployee, getRol, getDepartment, getToken, getId } = useContext(NewContext);
  const [dataCard, setDataCard] = useState();
  const [dataStatus, setDataStatus] = useState(null);
  const [arrivals, setArrivals] = useState();
  const [dateBirthdays, setDateBirthdays] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const { month, setMonth } = useContext(NewContext);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Estado para el mes seleccionado

  useEffect(() => {
    if (getDepartment) {
      setSelectedDepartments(
        Array.isArray(getDepartment) ? getDepartment : [getDepartment]
      );
    }
  }, [getDepartment]);

  useEffect(() => {
    const fetchData = async () => {
      if (getToken && getId) {
        try {
          setIsLoading(true);
          const departmentIds = selectedDepartments.length > 0
            ? selectedDepartments.join(',')
            : '0'; // Usa '0' si no hay departamentos

          if (['ROLE_ADMIN', 'ROLE_RRHH', 'ROLE_SUPERVISOR'].includes(getRol)) {
            await Promise.all([
              stadiscics(departmentIds),
              status(departmentIds),
              stadiscicsArray(),
              birthdays(selectedMonth + 1),
            ]);
          }
        } catch (error) {
          console.error('Error en fetchData:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [getToken, getId, getRol, selectedDepartments, selectedMonth]); // Asegúrate de incluir `selectedMonth` aquí


  const stadiscics = async (departmentIds) => {
    try {
      const companyParam = -1;
      const res = await getStadisctic(companyParam, departmentIds, selectedMonth);
      setDataCard(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const status = async (departmentIds) => {
    try {
      const companyParam = -1;
      const res = await getStatus(companyParam, departmentIds);
      setDataStatus(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const stadiscicsArray = async () => {
    try {
      const res = await getStadiscticArrival();
      setArrivals(res.data.slice(0, 4));
    } catch (error) {
      console.error(error);
    }
  };

const birthdays = async (monthParam) => {
  try {
    const res = await getBirdays(monthParam);
    setDateBirthdays(res.data || []);
  } catch (error) {
    console.error(error);
    setDateBirthdays([]);
  }
};

  if (!(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR')) {
    return null;
  }


  const handleChangeFilter = (monthSelected) => {
    setSelectedMonth(monthSelected);
    setMonth(monthSelected);
  };

  return (
    <div className='contenedorHome'>
      <LoadingOverlay isLoading={isLoading} />
      <div className="holaTitulo">
        <span className='holaTituloColor'>¡Hola!</span>
        <span className="nombreTitulo">
          {selectedEmployee && `${selectedEmployee?.person.name} ${selectedEmployee?.person.lastname}`}
        </span>
      </div>
      <div className='contenedorCardsHome'>
        <CardHome msj='Total de trabajadores' number={dataStatus?.employee}>
          <FontAwesomeIcon icon={faUser} className='iconCardHome' />
        </CardHome>
        <CardHome msj='Empleados que trabajan hoy' number={dataStatus?.employeesWorkingToday}>
          <FontAwesomeIcon icon={faBriefcase} className='iconCardHome2' />
        </CardHome>
        <CardHome msj='Empleados que aun no ficharon' number={dataStatus?.absentEmployees}>
          <FontAwesomeIcon icon={faFaceFrownOpen} className='iconCardHome3' />
        </CardHome>
        <CardHome msj='Llegadas tardes' number={dataStatus?.lateEmployees}>
          <FontAwesomeIcon icon={faClock} className='iconCardHome4' />
        </CardHome>
        <CardHome msj='Visitantes externos' number={dataStatus?.visitor}>
          <FontAwesomeIcon icon={faBuildingUser} className='iconCardHome5' />
        </CardHome>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h3 className="nombreTitulo" style={{ width: '202px', marginLeft: '10px' }}>Indicador Mensual</h3>
        <OptionComponenteRegister
          handleChangeFilter={handleChangeFilter}
        />
      </div>
      <div className='contenedorCardsHome'>
        <CardHome>
          <div className="titleWithIcon">
            <p className="titleCard">Próximas celebraciones</p>
            <FontAwesomeIcon icon={faCakeCandles} />
          </div>
          <CardBirthday birthdays={dateBirthdays} />
        </CardHome>

        <CardHome>
          <div className="titleWithIcon">
            <p className="titleCard">Métricas generales</p>
            <FontAwesomeIcon icon={faChartPie} />
          </div>
          <MetricsHome
            asistencias={dataCard?.assistentPercentage}
            ausencias={dataCard?.absencesPercentage}
            tardanzas={dataCard?.lateArrivalsPercentage}
            noFicharon={dataCard?.absencesPercentage}
          />
        </CardHome>
        <CardHome>
          <div className="titleWithIcon">
            <p className="titleCard">Top tardanzas</p>
            <FontAwesomeIcon icon={faClock} />
          </div>
          <CardArrivals arrivals={arrivals} />
        </CardHome>
      </div>
    </div>
  );
};

export default Home;

import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Solicitudes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import { getLateById } from "../utils/peticionesApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";

const Tardanzas = () => {
  const { getToken } = useContext(NewContext);
  const [tardanzas, setTardanzas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();

  // Cargar ausencias
  useEffect(() => {
    if (getToken && id) {
      listarTardanzas();
    }
  }, [getToken, id]);

  const listarTardanzas = async () => {
    try {
      const response = await getLateById(id);
      if (response && response.data) {
        setTardanzas(response.data);
      } else {
        console.error("Error: La respuesta no contiene datos.");
      }
    } catch (error) {
      console.error("Error al hacer la solicitud:", error.message);
    }
  };

  const formatDate = (date) => {
    if (!date) return "Fecha inválida";
    if (typeof date === "string") {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
    return "Fecha inválida";
  };

  // Filtrar datos (temporalmente sin filtros)
  const filtradoTardanzas = useMemo(() => {
    return tardanzas; // Sin filtrado para simplificar
  }, [tardanzas]);

  // Paginación
  const tardanzasPorPagina = 15;
  const paginatedTardanzas = useMemo(() => {
    const start = currentPage * tardanzasPorPagina;
    const end = start + tardanzasPorPagina;
    return filtradoTardanzas.slice(start, end);
  }, [currentPage, filtradoTardanzas]);

  // Tabla de filas
  const TableRow = ({ tardanzas }) => (
    <tr>
      <td>{tardanzas.employee_first_name}</td>
      <td>{tardanzas.employee_last_name}</td>
      <td>{formatDate(tardanzas.date_absence)}</td>
    </tr>
  );

  return (
    <div className="contenedor-solicitudes">
      <button onClick={() => window.history.back()} className="buttonVolverObj">
        <FontAwesomeIcon icon={faCircleLeft} />
        Volver
      </button>
      <p className="titulo">Tardanzas</p>

      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {paginatedTardanzas.length === 0 ? (
              <tr>
                <td colSpan="3">No se encontraron resultados.</td>
              </tr>
            ) : (
              paginatedTardanzas.map((tardanzas, index) => (
                <TableRow key={index} tardanzas={tardanzas} />
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <CustomPagination
          currentPage={currentPage}
          totalPages={Math.ceil(filtradoTardanzas.length / tardanzasPorPagina)}
          setCurrentPage={setCurrentPage}
          hasNextPage={currentPage < Math.ceil(filtradoTardanzas.length / tardanzasPorPagina) - 1}
        />
      </div>
    </div>
  );
};

export default Tardanzas;
import React, { useContext, useEffect, useState } from "react";
import "../css/Reloj.css";
import "../css/RelojReportes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import OptionComponentReporteEventos from '../components/OptionComponentReporteEventos';
import { getAllEmpSummaryReport, getExportEmpSummaryReportFile } from "../utils/peticionesApi";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SelectEmpleados from "../components/SelectEmpleados";
import SelectDepartamentos from "../components/SelectDepartamentos";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const RelojResumen = () => {
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());;
    const [uuid, setUuid] = useState(null);
    const [idDepartamento, setIdDepartamento] = useState(null);
    const dateStringInicio = selectedStartDate.toDateString();
    const formattedDateInicio = formatDate(dateStringInicio);
    const dateStringFin = selectedEndDate.toDateString();
    const formattedDateFin = formatDate(dateStringFin);
    const { getToken, getId, getAlert, getDepartment } = useContext(NewContext);
    const [selectedDepartments, setSelectedDepartments] = useState(getDepartment);
    const [transactions, setTransactions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalView, setModalView] = useState(false);
    const [modalViewExport, setModalViewExport] = useState(false);
    const [detalle, setDetalle] = useState(true)
    const [detail, setDetail] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [dateExport, setDateExport] = useState({
        exportDesde: null,
        exportHasta: null
    })

    const [selectRegistry, setSelectRegistry] = useState(null);
    const navigate = useNavigate();

    function formatDate(dateString) {
        const months = {
            Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
            Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
        };
        const [nameDay, monthStr, day, year] = dateString.split(' ');
        const month = months[monthStr];
        return `${year}-${month}-${day}`;
    }

    const listTransactions = async (currentPage, employeesPerPage) => {
        try {

            const companyParam = -1;
            const employeesPerPage = 20;
            const departmentIds = selectedDepartments.join(',');

            const response = await getAllEmpSummaryReport(
                formattedDateInicio,
                formattedDateFin,
                currentPage,
                employeesPerPage,
                companyParam,
                departmentIds // Pasar el filtro adicional si aplica
            );
            console.log(response.data.data);
            setTransactions(response.data.data);
            const totalPages = Math.ceil(response.data.count / employeesPerPage);
            setTotalPages(totalPages);
        } catch (error) {
            console.error("Error al obtener transacciones:", error);
        }
    };

    useEffect(() => {
        if (getToken && getId) {
            listTransactions(currentPage);
        }
    }, [selectRegistry, currentPage, selectedEndDate, selectedDepartments]);


    const filterTransaction = transactions.filter(transaction => {
        const fullName = (transaction.first_name + ' ' + transaction.last_name).toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
    });

    const TableRow = ({ transaction }) => {
        const date = new Date(transaction.att_date);
        const formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
        return (
            <tr>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.first_name}</td>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.last_name}</td>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.dept_name}</td>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.total_worked}</td>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.total_absent}</td>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.total_late}</td>
                <td style={{ textAlign: 'center', fontSize: '13px' }}>{transaction.total_time}</td>
            </tr>
        );
    };

    const handleDateChange = newDates => {
        if (newDates.length === 1) {
            setSelectedStartDate(newDates[0]);
            setSelectedEndDate(null);
        } else if (newDates.length === 2) {
            setSelectedStartDate(newDates[0]);
            setSelectedEndDate(newDates[1]);
            setModalView(!modalView);
        }
    };

    const handleExportChange = e => {
        setDateExport({ ...dateExport, [e.target.name]: e.target.value });
    };

    const submitExport = async () => {
        if (!dateExport.exportDesde || !dateExport.exportHasta) {
            return getAlert({ msj: `No se ingresó la fecha.`, tipe: 'error' });
        }
        if (!idDepartamento) {
            return getAlert({ msj: `No se selecciono el departamento.`, tipe: 'error' });
        }
        try {
            const exportType = detail ? "pdf" : "xls";

            const res = await getExportEmpSummaryReportFile(
                dateExport.exportDesde,
                dateExport.exportHasta,
                0,
                2000,
                -1,
                34,
                exportType
            );
            const contentDisposition = res.headers['content-disposition'] || '';
            const fileNameMatch = contentDisposition.match(/filename="([^"]+)"/);
            let fileName = 'archivo_sin_nombre';
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            const blob = new Blob([res.data], { type: res.headers['content-type'] || 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log("Error en submitExport:", error);
        }
    };

    return (
        <div className="contenedor-dispositivo">
            <button
                onClick={() => navigate("/reloj")}
                className="buttonVolverObj"
            >
                <FontAwesomeIcon icon={faCircleLeft} />
                Volver
            </button>
            <div>
                <p className="titulo">{"Resumen Por Empleado"}</p>
                <OptionComponentReporteEventos
                    type="text"
                    placeHolder="Buscar por nombre, apellido o área"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    modalView={modalView}
                    setModalView={setModalView}
                    modalViewExport={modalViewExport}
                    setModalViewExport={setModalViewExport}
                />
                {modalView ? (
                    <div className="modalCalendar">
                        <Calendar onChange={handleDateChange} value={selectedEndDate ? [selectedStartDate, selectedEndDate] : selectedStartDate} selectRange={true} />
                    </div>
                ) : null}

                {modalViewExport ? (
                    <div className="modalExport">
                        <div className="contenedorBotonesFiltroEmpleado">
                            <button onClick={() => setDetalle(!detalle)} className={`${!detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>
                                Por empleado
                            </button>
                            <button onClick={() => setDetalle(!detalle)} className={`${detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>
                                Todos los empleados
                            </button>
                        </div>
                        {!detalle ? (
                            <div className="inputSelectEmpleado">
                                <SelectEmpleados setUuid={setUuid} />
                            </div>
                        ) : null}
                        {detalle ? (
                            <div className="inputSelectEmpleado">
                                <SelectDepartamentos setIdDepartamento={setIdDepartamento} />
                            </div>
                        ) : null}
                        <div className="contenedorDesadeHasta">
                            <div>
                                <p>Desde</p>
                                <input className="inputDate" type="date" name="exportDesde" onChange={handleExportChange} />
                            </div>
                            <div>
                                <p>Hasta</p>
                                <input className="inputDate" name="exportHasta" onChange={handleExportChange} type="date" />
                            </div>
                        </div>
                        <div className="contenedorRarioButtons">
                            <div className="contenedorRadio">
                                <input type="radio" value={true} name="detalle" checked={detail === true} onChange={e => setDetail(e.target.value === 'true')} />
                                <p>PDF</p>
                            </div>
                            <div className="contenedorRadio">
                                <input type="radio" value={false} name="detalle" checked={detail === false} onChange={e => setDetail(e.target.value === 'true')} />
                                <p>XLS</p>
                            </div>
                        </div>
                        <Button onClick={submitExport} msj="Exportar Excel" tipe="violeta" />
                    </div>
                ) : null}

                <div className="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th className="label-cell" style={{ width: "10%", fontSize: "15px" }}>Nombre</th>
                                <th className="label-cell" style={{ width: "10%", fontSize: "15px" }}>Apellido</th>
                                <th className="label-cell" style={{ width: "10%", fontSize: "15px" }}>Departamento</th>
                                <th className="label-cell" style={{ width: "13%", fontSize: "15px" }}>
                                    Tiempo Trabajado <span style={{ fontSize: "10px", color: "gray" }}>(horas)</span>
                                </th>
                                <th className="label-cell" style={{ width: "12%", fontSize: "15px" }}>
                                    Ausentes <span style={{ fontSize: "10px", color: "gray" }}>(días)</span>
                                </th>
                                <th className="label-cell" style={{ width: "12%", fontSize: "15px" }}>
                                    Llegas Tardes <span style={{ fontSize: "10px", color: "gray" }}>(minutos)</span>
                                </th>
                                <th className="label-cell" style={{ width: "12%", fontSize: "15px" }}>Extras</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterTransaction.length === 0 ? (
                                <tr>
                                    <td colSpan="7">Por favor realizar busqueda detallada de empleados entre el rango deseado.</td>
                                </tr>
                            ) : (
                                filterTransaction.map((transaction, index) => (
                                    <TableRow key={index} transaction={transaction} index={index} />
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Solo mostrar la paginación si hay transacciones listadas */}
                {filterTransaction.length > 0 && (
                    <div className="d-flex justify-content-center mt-4">
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RelojResumen;
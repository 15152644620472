import React, { useContext, useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import '../../css/AgregarSuspensionModal.css';
import { getPersonaId, postSuspension, putSuspension } from '../../utils/peticionesApi';
import { NewContext } from '../../utils/ContextProvider';
import { useParams } from "react-router-dom";

const AgregarSuspensionModal = ({
    rol,
    closeModalSuspension,
    suspension,
    handleSubmitSuspension,
}) => {
    const { getAlert, selectedEmployee } = useContext(NewContext);
    const [suspensionDate, setSuspensionDate] = useState('');
    const [observations_suspension, setObservations] = useState('');
    const [AppliedDays, setAppliedDays] = useState('');
    const [selectedEmpleadoId, setSelectedEmpleadoId] = useState('');
    const [empleados, setEmpleados] = useState([]);
    const [nombre, setNombre] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [active, setActive] = useState(false);
    const { id } = useParams();
    const [persona, setPersona] = useState(null);

    useEffect(() => {
        if (suspension) {
            setSelectedEmpleadoId(suspension.uuid_employee);
            const parsedDate = parseDateFromBackend(suspension.suspension_date || "");
            setSuspensionDate(parsedDate);
            setObservations(suspension.reason || "");
            setAppliedDays(suspension.applied_days || "");
            setActive(suspension.status === "Aprobada");
            setNombre({
                name: suspension.employee_name || "",
                lastname: suspension.employee_last_name || "",
                id: suspension.uuid_employee,
            });
            setIsEditing(true);
        } else {
            setSuspensionDate("");
            setObservations("");
            setAppliedDays("");
            setActive(false);
            setNombre("");
            setSelectedEmpleadoId(id);
            setIsEditing(true);
        }
    }, [suspension]);

    function parseDateFromBackend(dateStr) {
        if (!dateStr) return "";
        const [day, month, year] = dateStr.split("-");
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    const formatDateToDDMMYYYY = (date) => {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
    };

    const handleEstadoChange = (event) => {
        setActive(event.target.value);
    };

    const listarPersona = async () => {
        try {
            const response = await getPersonaId(id);
            setPersona(response.data);
        } catch (error) {
            console.error("Error al listar persona:", error);
            setPersona(null);
        }
    };

    useEffect(() => {
        listarPersona();
    }, [id]);

    const onSubmit = async () => {
        const dto = {
            id: suspension?.id,
            applied_days: AppliedDays,
            reason: observations_suspension,
            status: active ? "Aprobada" : "Pendiente",
            suspension_date: formatDateToDDMMYYYY(suspensionDate),
            uuid_employee: suspension?.uuid_employee || selectedEmpleadoId,
            employee_name: suspension?.employee_name || "",
            employee_last_name: suspension?.employee_last_name || "",
        };

        try {
            if (!suspension) {
                await postSuspension(dto);
                getAlert({ msj: "Suspensión agregada correctamente", tipe: 'succes' });
            } else {
                await putSuspension(dto);
                getAlert({ msj: "Suspensión actualizada correctamente", tipe: 'succes' });
            }
            handleSubmitSuspension(dto);
            closeModalSuspension();

        } catch (error) {
            getAlert({
                msj: "Hubo un error al crear la suspensión. Por favor, intenta de nuevo.",
                tipe: 'error',
            });
            console.error("Error al enviar la suspensión:", error);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const suspensionDto = {
        id: suspension?.id ?? null,
        applied_days: AppliedDays,
        reason: observations_suspension,
        status: active ? "Aprobada" : "Pendiente",
        suspension_date: formatDateToDDMMYYYY(suspensionDate),
        uuid_employee: suspension?.uuid_employee || selectedEmpleadoId,
        employee_name: suspension?.employee_name || "",
        employee_last_name: suspension?.employee_last_name || "",
    };

    return (
        <>
            <div className='contenedor-modal-suspensiones'>
                <div>
                    <div>
                        <InputLabel id="suspension-label">Solicitante</InputLabel>
                        {persona ? (
                            <p>
                                {persona.person?.name} {persona.person?.lastname}
                            </p>
                        ) : (
                            <p>Cargando datos del solicitante...</p>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="contenedor-fecha-inicio half-width">
                        <TextField
                            id="startDate"
                            label="Fecha de Suspensión"
                            type="date"
                            value={suspensionDate}
                            onChange={(event) => setSuspensionDate(event.target.value)}
                            className="customTextField"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={!isEditing}
                            variant="outlined"
                        />
                    </div>
                    <div className="selectContainer half-width">
                        <FormControl fullWidth>
                            <InputLabel id="estado-label">Estado</InputLabel>
                            <Select
                                labelId="estado-label"
                                id="estado"
                                value={active}
                                onChange={handleEstadoChange}
                                className="customSelect-suspensiones"
                                disabled={!isEditing}
                                variant="outlined"
                            >
                                <MenuItem value={true}>Aprobado</MenuItem>
                                <MenuItem value={false}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="contenedor-observaciones">
                    <TextField
                        id="diasAplicados"
                        label="Días aplicados"
                        value={AppliedDays}
                        onChange={(event) => setAppliedDays(event.target.value.replace(/\D/g, ''))} // Solo permite números
                        className="customTextField"
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Acepta solo números en dispositivos móviles
                    />
                </div>

                <div className="contenedor-observaciones">
                    <TextField
                        id="observations"
                        label="Observaciones"
                        multiline
                        minRows={4}
                        value={observations_suspension}
                        onChange={(event) => setObservations(event.target.value)}
                        className="customTextField"
                        disabled={!isEditing}
                        variant="outlined"
                    />
                </div>

            </div>
            <div className="button-suspension">
                <button className="btn-cancelar" onClick={closeModalSuspension}>Cancelar</button>
                {suspension && !isEditing && (
                    <button className="btn-editar" onClick={handleEditClick}>Editar</button>
                )}
                {isEditing && (
                    <button className="btn-agregar" onClick={onSubmit}>{suspension ? "Guardar cambios" : "Agregar suspensión"}</button>
                )}
            </div>
        </>
    );
};

export default AgregarSuspensionModal;
import { React, useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import Button from '../../components/Button.jsx'
import {
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  TextField,
  Grid,
} from "@material-ui/core";
import ImageCrop from "../../library/imageCrop";
import '../../css/AgregarEmpleadoModal.css'
import { getCity, getDistrito } from "../../utils/peticionesApi";

const AgregarEmpleadoModal = ({
  closeModal,
  formData,
  setFormData,
  handleChange,
  handleSubmit,
  error,
  currentPage,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [setUserProfilePic] = useState("");
  const [scaleValue, setScaleValue] = useState(1);
  const [editor, setEditor] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [distrito, setDistrito] = useState(null);
  const [ciudad, setCiudad] = useState(null);

  useEffect(() => {
    listarDistrito()
    listarCiudad()
  }, [])


  useEffect(() => {
    console.log("Updated formData:", formData);
  }, [formData]);

  const availableRoles = ["ROLE_ADMIN", "ROLE_USER", "ROLE_RRHH", "ROLE_SUPERVISOR"];
  const roleLabels = {
    "ROLE_ADMIN": "Administrador",
    "ROLE_USER": "Empleado",
    "ROLE_RRHH": "RRHH",
    "ROLE_SUPERVISOR": "Supervisor"
  };

  const validateStep = (step) => {
    const newErrors = {};

    if (step === 0) {
      if (!formData.person.name) newErrors.name = "El nombre es obligatorio";
      if (!formData.person.lastname)
        newErrors.lastname = "El apellido es obligatorio";
      if (!formData.person.national_id)
        newErrors.national_id = "El DNI es obligatorio";
      if (!formData.person.date_of_birth)
        newErrors.date_of_birth = "La fecha de nacimiento es obligatoria";
      if (!formData.blood_group)
        newErrors.blood_group = "El grupo sanguíneo es obligatorio";
      if (!formData.cell_phone)
        newErrors.cell_phone = "El celular es obligatorio";
    } else if (step === 1) {
      if (!formData.address.street)
        newErrors.street = "La calle es obligatoria";
      if (!formData.address.number)
        newErrors.number = "El número es obligatorio";
    } else if (step === 2) {
      if (!formData.city.id_district)
        newErrors.id_district = "El distrito es obligatorio";
      if (!formData.city.name) newErrors.nameCity = "La ciudad es obligatoria";
      if (!formData.city.zip_code)
        newErrors.zip_code = "El código postal es obligatorio";
    } else if (step === 5) {
      if (!formData.email) {
        newErrors.email = "Email es obligatorio";
      }
      if (!formData.user.username) {
        newErrors.username = "Usuario Obligatorio";
      }
      if (!formData.user.roles || formData.user.roles === "") {
        newErrors.roles = "Debe seleccionar un rol";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const listarDistrito = async () => {
    try {
      const response = await getDistrito(currentPage)
      setDistrito(response.data.content);
    } catch (error) {
      console.log(error)
    }

  };


  const listarCiudad = async () => {
    try {
      const response = await getCity(currentPage)
      setCiudad(response.data.content);
    } catch (error) {
      console.log(error)
    }
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const steps = [
    {
      label: "Datos personales",
    },
    {
      label: "Domicilio",
    },
    {
      label: "Ciudad",
    },
    {
      label: "Historial laboral",
    },
    {
      label: "Estado",
    },
    {
      label: "Usuario",
    },
    {
      label: "Reloj Asistencia",
    },
    {
      label: "Foto",
    },
  ];
  const roles = [
    { id: 1, name: "ROLE_ADMIN" },
    { id: 2, name: "ROLE_RRHH" },
    { id: 3, name: "ROLE_USER" },
    { id: 4, name: "ROLE_SUPERVISOR" },
    { id: 5, name: "ROLE_OPERADOR" },
  ];

  const setEditorRef = (editor) => setEditor(editor);

  const onCrop = () => {
    if (editor !== null) {
      const url = editor.getImageScaledToCanvas().toDataURL();
      setUserProfilePic(url);
    }
  };

  const onScaleChange = (scaleChangeEvent) => {
    const scaleValue = parseFloat(scaleChangeEvent.target.value);
    setScaleValue(scaleValue);
  };

  const profilePicChange = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0];
    const { type } = file;
    if (
      !(
        type.endsWith("jpeg") ||
        type.endsWith("png") ||
        type.endsWith("jpg") ||
        type.endsWith("gif")
      )
    ) {
      // Puedes manejar el caso donde el tipo de archivo no es válido
    } else {
      setSelectedImage(file);
    }
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      save_in_zkteco: checked,
    }));
  };

  return (
    <>
      <div className="container-modal-form-empleado">
        <Stepper
          steps={steps}
          activeStep={activeStep}
          styleConfig={{
            activeBgColor: "#8F3B8C",
            activeTextColor: "#fff",
            inactiveBgColor: "#fff",
            inactiveTextColor: "#2b7cff",
            completedBgColor: "#fff",
            completedTextColor: "#2b7cff",
            size: "1.4rem",
          }}
          onSelect={setActiveStep}
          className={"stepper"}
          stepClassName={"stepper__step"}
        />
        <div>
          {activeStep === 0 && (
            <div className="text-modal">
              <List>
                <Grid
                  container
                >
                  <Grid item xs={11} sm={11} md={6} >
                    <ListItem className="list-modal">
                      <ListItemIcon>
                        <i className="fa-solid fa-circle-user"></i>
                      </ListItemIcon>
                      <TextField
                        id="standard-basic"
                        placeholder="Nombre"
                        label="Nombre"
                        value={formData.person.name}
                        variant="standard"
                        name="person.name"
                        onChange={handleChange}
                        className="item-list-modal"
                        error={errors.name ? !!errors.name : undefined}
                        helpertext={errors.name}
                      />
                    </ListItem>

                    <ListItem className="list-modal">
                      <ListItemIcon>
                        <i className="fa-solid fa-circle-user"></i>
                      </ListItemIcon>
                      <TextField
                        id="standard-basic"
                        placeholder="Apellido"
                        label="Apellido"
                        value={formData.person.lastname}
                        variant="standard"
                        name="person.lastname"
                        onChange={handleChange}
                        className="item-list-modal"
                        error={errors.lastname ? !!errors.lastname : undefined}
                        helpertext={errors.lastname}
                      />
                    </ListItem>
                    <ListItem className="list-modal">
                      <ListItemIcon>
                        <i className="fa-regular fa-address-card"></i>
                      </ListItemIcon>
                      <TextField
                        id="standard-basic"
                        placeholder="DNI"
                        label="DNI"
                        value={formData.person.national_id}
                        variant="standard"
                        name="person.national_id"
                        onChange={handleChange}
                        className="item-list-modal"
                        error={errors.national_id ? !!errors.national_id : undefined}
                        helpertext={errors.national_id}
                      />
                    </ListItem>
                  </Grid>
                  <Grid item xs={11} sm={11} md={6} >
                    <ListItem className="list-modal">
                      <ListItemIcon>
                        <i className="fa-solid fa-cake-candles"></i>
                      </ListItemIcon>
                      <TextField
                        id="standard-basic"
                        placeholder="Fecha de nacimiento - formato 01-01-2023"
                        label="Fecha de nacimiento - (formato 01-01-2023)"
                        value={formData.person.date_of_birth}
                        variant="standard"
                        name="person.date_of_birth"
                        onChange={handleChange}
                        className="item-list-modal"
                        error={errors.date_of_birth ? !!errors.date_of_birth : undefined}
                        helpertext={errors.date_of_birth}
                      />
                    </ListItem>

                    <ListItem className="list-modal">
                      <ListItemIcon>
                        <i className="fa-solid fa-circle-user"></i>
                      </ListItemIcon>
                      <TextField
                        id="standard-basic"
                        placeholder="Grupo sanguíneo"
                        label="Grupo sanguíneo"
                        value={formData.blood_group}
                        variant="standard"
                        name="blood_group" // Corregido
                        onChange={handleChange}
                        className="item-list-modal"
                        error={errors.blood_group ? !!errors.blood_group : undefined}
                        helpertext={errors.blood_group}
                      />
                    </ListItem>

                    <ListItem className="list-modal">
                      <ListItemIcon>
                        <i className="fa-solid fa-mobile-screen"></i>
                      </ListItemIcon>
                      <TextField
                        id="standard-basic"
                        placeholder="Celular"
                        label="Celular"
                        value={formData.cell_phone}
                        variant="standard"
                        name="cell_phone" // Corregido
                        onChange={handleChange}
                        className="item-list-modal"
                        error={errors.cell_phone ? !!errors.cell_phone : undefined}
                        helpertext={errors.cell_phone}
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </List>
            </div>
          )}
        </div>
        <div>
          {activeStep === 1 && (
            <div className="text-modal">
              <Grid item xs={11} sm={11} md={12}>
                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-location-arrow"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Calle"
                    label="Calle"
                    variant="standard"
                    name="address.street"
                    value={formData.address.street}
                    onChange={handleChange}
                    className="item-list-modal"
                    error={errors.street ? !!errors.street : undefined}
                    helpertext={errors.street}
                  />
                </ListItem>
                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-hashtag"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Número"
                    label="Número"
                    variant="standard"
                    name="address.number"
                    value={formData.address.number}
                    onChange={handleChange}
                    className="item-list-modal"
                    error={errors.number ? !!errors.number : undefined}
                    helpertext={errors.number}
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-regular fa-building"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Piso"
                    label="Piso"
                    variant="standard"
                    name="address.floor"
                    value={formData.address.floor}
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-hashtag"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Departamento"
                    label="Departamento"
                    variant="standard"
                    name="address.apartament"
                    value={formData.address.apartament}
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
              </Grid>
            </div>
          )}
        </div>

        <div>
          {activeStep === 2 && (
            <div className="text-modal">
              <Grid item xs={11} sm={11} md={12}>
                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-city"></i>
                  </ListItemIcon>
                  <select
                    name="city.id_district"
                    value={formData.city.id_district}
                    onChange={handleChange}
                    className="select-css"
                  >
                    <option value="">Selecciona un distrito</option>
                    {distrito.map((distrit) => (
                      <option key={distrit.id} value={distrit.id}>
                        {distrit.name}
                      </option>
                    ))}
                  </select>
                  {errors.id_district && (
                    <span className="error-text">{errors.id_district}</span>
                  )}
                </ListItem>

                {ciudad && (
                  <ListItem className="list-modal">
                    <ListItemIcon>
                      <i className="fa-solid fa-city"></i>
                    </ListItemIcon>
                    {ciudad && (
                      <select
                        name="city.name"
                        value={formData.city.name}
                        onChange={handleChange}
                        className="select-css"
                        error={errors.nameCity ? !!errors.nameCity : undefined}

                      >
                        <option helpertext={errors.nameCity} value="">Selecciona una ciudad</option>
                        {ciudad.map((city) => (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {errors.nameCity && (
                      <span className="error-text">{errors.nameCity}</span>
                    )}
                  </ListItem>
                )}

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-hashtag"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Codigo Postal"
                    label="Codigo Postal"
                    value={formData.city.zip_code}
                    variant="standard"
                    name="city.zip_code"
                    onChange={handleChange}
                    error={errors.zip_code ? !!errors.zip_code : undefined}
                    helpertext={errors.zip_code}
                  />
                </ListItem>
              </Grid>
            </div>
          )}
        </div>
        <div>
          {activeStep === 3 && (
            <div className="text-modal">
              <Grid item xs={11} sm={11} md={12}>
                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-comment"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Comentarios del historial"
                    label="Comentarios del historial"
                    value={formData.history.comments}
                    variant="standard"
                    name="history.comments"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-briefcase"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Historial laboral"
                    label="Historial laboral"
                    value={formData.history.company}
                    variant="standard"
                    name="history.company"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#999",
                    paddingTop: "10px",
                  }}
                >
                  formato 01-01-2023
                </span>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-briefcase"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Historial desde"
                    label="Historial desde"
                    value={formData.history.from_date}
                    variant="standard"
                    name="history.from_date"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#999",
                    paddingTop: "10px",
                  }}
                >
                  formato 01-01-2023
                </span>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-briefcase"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Referencias anteriores"
                    label="Referencias anteriores"
                    value={formData.history.reference}
                    variant="standard"
                    name="history.reference"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-briefcase"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Historial finalizado"
                    label="Historial finalizado"
                    value={formData.history.to_date}
                    variant="standard"
                    name="history.to_date"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#999",
                    paddingTop: "10px",
                  }}
                >
                  formato 01-01-2023
                </span>
              </Grid>
            </div>
          )}
        </div>
        <div>
          {activeStep === 4 && (
            <div className="text-modal">
              <Grid item xs={11} sm={11} md={12}>
                <ListItem className="list-modal-check">
                  <ListItemIcon>
                    <i className="fa-solid fa-circle-user"></i>
                  </ListItemIcon>
                  Activo
                  <Checkbox
                    id="standard-basic"
                    placeholder="Activo"
                    label="Activo"
                    value={formData.status.active}
                    variant="standard"
                    name="status.active"
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-comment"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Comentarios de estado"
                    label="Comentarios de estado"
                    value={formData.status.comments}
                    variant="standard"
                    name="status.comments"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-regular fa-calendar"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Fecha de entrada"
                    label="Fecha de entrada"
                    value={formData.status.entry_date}
                    variant="standard"
                    name="status.entry_date"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#999",
                    paddingTop: "10px",
                  }}
                >
                  formato 01-01-2023
                </span>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-regular fa-calendar"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Fecha de salida"
                    label="Fecha de salida"
                    value={formData.status.leaving_date}
                    variant="standard"
                    name="status.leaving_date"
                    onChange={handleChange}
                    className="item-list-modal"
                  />
                </ListItem>
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#999",
                    paddingTop: "10px",
                  }}
                >
                  formato 01-01-2023
                </span>
              </Grid>
            </div>
          )}
        </div>
        <div>
          {activeStep === 5 && (
            <div className="text-modal">
              <Grid item xs={11} sm={11} md={12}>
                <ListItem className="list-modal-check">
                  <ListItemIcon>
                    <i className="fa-solid fa-circle-user"></i>
                  </ListItemIcon>
                  Usuario activo
                  <Checkbox
                    id="standard-basic"
                    placeholder="Usuario activo"
                    label="Usuario activo"
                    value={formData.user.active}
                    variant="standard"
                    name="user.active"
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-envelope"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Ingrese su email"
                    label="Ingrese su email"
                    value={formData.email}
                    variant="standard"
                    name="email"
                    onChange={handleChange}
                    className="item-list-modal"
                    error={errors.email ? !!errors.email : undefined}
                    helperText={errors.email}
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-lock"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder={formData.person.national_id}
                    label="Constraseña"
                    value={formData.person.national_id}
                    variant="standard"
                    name="national_id"
                    onChange={handleChange}
                    className="item-list-modal"
                    disabled
                  />
                </ListItem>

                <ListItem className="list-modal">
                  <ListItemIcon>
                    <i className="fa-solid fa-envelope"></i>
                  </ListItemIcon>
                  <TextField
                    id="standard-basic"
                    placeholder="Ingrese su usuario"
                    label="Ingrese su usuario"
                    value={formData.user.username}
                    variant="standard"
                    name="user.username"
                    onChange={handleChange}
                    className="item-list-modal"
                    error={errors.username ? !!errors.username : undefined}
                    helperText={errors.username}
                  />
                </ListItem>
                <ListItem className="list-modal">
                  <ListItemIcon>
                    <select
                      name="user.roles"
                      value={formData.user.roles || ""}
                      onChange={handleChange}
                      className="select-css"
                    >
                      <option value="" disabled>
                        Seleccione un rol
                      </option>
                      {availableRoles.map((role) => (
                        <option key={role} value={role}>
                          {roleLabels[role]}
                        </option>
                      ))}
                    </select>
                    {errors.roles && <span className="error-text">{errors.roles}</span>}
                  </ListItemIcon>
                  {errors.roles && <span className="error-text">{errors.roles}</span>}
                </ListItem>
              </Grid>
            </div>
          )}
        </div>
        <div>
          {activeStep === 6 && (
            <div>
              <ListItem className="list-modal-check">
                <ListItemIcon>
                  <i className="fa-solid fa-circle-user"></i>
                </ListItemIcon>
                Crear usuario en Reloj Zkteco
                <Checkbox
                  id="standard-basic"
                  placeholder="Crear usuario en Reloj Zkteco"
                  label="Crear usuario en Reloj Zkteco"
                  checked={formData.save_in_zkteco || false}
                  onChange={handleCheckboxChange}
                  name="save_in_zkteco"
                />
              </ListItem>
            </div>
          )}
        </div>
        <div>
          {activeStep === 7 && (
            <div className="text-modal">
              <Grid item xs={11} sm={11} md={12}>
                <center>
                  <div className="mb-3">
                    <h4 htmlFor="formFile" className="form-label">
                      Foto de perfil
                    </h4>
                    <input
                      className="form-control"
                      type="file"
                      accept="image/png, image/jpeg"
                      name="profilePicBtn"
                      id="formFile"
                      onChange={profilePicChange}
                    />
                  </div>
                  <ImageCrop
                    imageSrc={selectedImage}
                    setEditorRef={setEditorRef}
                    onCrop={onCrop}
                    scaleValue={scaleValue}
                    onScaleChange={onScaleChange}
                  />
                </center>
              </Grid>
            </div>
          )}
        </div>
        <div className="contenedor-buttons-modal">
          {activeStep === 0 && (
            <Button
              msj='Cancelar'
              tipe="sinbg"
              onClick={closeModal}
            />

          )}
          {activeStep > 0 && (
            <Button
              tipe="sinbg"
              msj='Anterior'
              onClick={handleBack}
            />

          )}
          {activeStep < steps.length - 1 && (
            <Button
              msj='Siguiente'
              onClick={handleNext}
            />

          )}
          {activeStep === steps.length - 1 && (
            <Button onClick={() => handleSubmit(selectedImage)} msj='Guardar empleado' />
          )}
        </div>
      </div>
      {error !== null && (
        <center>
          <p style={{ padding: "10px", color: "red" }}>{error}</p>
        </center>
      )}
    </>
  );
};

export default AgregarEmpleadoModal;

/*
<ListItem className="list-modal">
                <ListItemIcon>
                  <i className="fa-solid fa-circle-user"></i>
                </ListItemIcon>
                Departamento
                <select
                  name="user.roles"
                  // value={formData.user.role.name}
                  value={formData.user.roles}
                  onChange={handleChange}
                  className="select-css"
                >
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </ListItem> */
import React, { useState, useContext } from 'react';
import "../../css/ImportarEmpleadoModal.css";
import { getImportEmployees } from "../../utils/peticionesApi";
import { NewContext } from "../../utils/ContextProvider";

const ImportarEmpleadoModal = ({ showModal, closeModal }) => {
    const [file, setFile] = useState(null);
    const { getAlert } = useContext(NewContext);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = async () => {
        if (!file) {
            getAlert({ msj: "Selecciona un archivo antes de importar.", tipe: 'warning' });
            return;
        }

        try {
            const response = await getImportEmployees(file);

            if (response.failureCount === 0) {
                getAlert({ msj: "Archivo importado exitosamente.", tipe: 'succes' });
                closeModal();
            } else {
                getAlert({
                    msj: `Importación completada con errores. ${response.failureCount} registros fallaron.`,
                    tipe: 'warning',
                });
            }
        } catch (error) {
            getAlert({
                msj: "Hubo un error al importar el archivo. Por favor, intenta de nuevo.",
                tipe: 'error',
            });
            console.error("Error en la importación:", error);
        }
    };

    return (
        showModal && (
            <div className="modal-overlay-employee">
                <div className="modal-content-employee">
                    <div className="modal-header-employee">
                        <h2>Importar Empleados</h2>
                        <button onClick={closeModal} className="close-button">X</button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="file-upload" className="file-upload">
                            Archivo de importación:
                            <input type="file" id="file-upload" onChange={handleFileChange} />
                        </label>
                        <p className="template-download">
                            <a href="/templates/plantilla-Empleandos.xlsx" download>Descargar plantilla</a>
                        </p>
                        <div className="table-preview">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Estado</th>
                                        <th>Fecha Alta Laboral</th>
                                        <th>Nombre</th>
                                        <th>Apellido</th>
                                        <th>DNI</th>
                                        <th>Fecha de Nacimiento</th>
                                        <th>Correo Electronico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>German</td>
                                        <td>Oyarzo</td>
                                        <td>10325699</td>
                                        <td>Jornada</td>
                                        <td>07:00</td>
                                        <td>17:00</td>
                                        <td>Trenes</td>
                                        <td>Hall Central</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="description">
                            <p>1. El encabezado en el archivo de plantilla es requerido</p>
                            <p>2. El Dni del Empleado, Nombres, Fecha de nacimiento son campos requeridos</p>
                            <p>3. Todos los valores de la columna deben ser en formato de TEXTO</p>
                            <p>4. Donde indica ZKteco, es true si se va a usar reloj de asistencia</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={handleSubmit} className="confirm-button">Confirmar</button>
                        <button onClick={closeModal} className="cancel-button">Cancelar</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ImportarEmpleadoModal;

import React from 'react'
import '../../css/OptionComponent.css'
import Select from 'react-select'

const OptionComponenteHome = ({ handleChangeFilter }) => {
  const month = new Date().getMonth()
  const options = [
    { label: 'Enero', value: 0 },
    { label: 'Febrero', value: 1 },
    { label: 'Marzo', value: 2 },
    { label: 'Abril', value: 3 },
    { label: 'Mayo', value: 4 },
    { label: 'Junio', value: 5 },
    { label: 'Julio', value: 6 },
    { label: 'Agosto', value: 7 },
    { label: 'Septiembre', value: 8 },
    { label: 'Octubre', value: 9 },
    { label: 'Noviembre', value: 10 },
    { label: 'Diciembre', value: 11 },
  ]

  const handleChange = (e) => {
    handleChangeFilter(e.value)
  }

  return (
    <div className='contenedorOptions'>
      <div className='contenedorInputOptionRegisters'>
        <Select className='inputMothComponent' options={options} defaultValue={options[month]} onChange={handleChange} />
      </div>
    </div>
  )
}

export default OptionComponenteHome;

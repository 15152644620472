import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Solicitudes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import AgregarSuspensionModal from "../forms/Suspension/AgregarSuspensionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft, faTrash, faPenToSquare, faFileArrowDown, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getSuspension, getSuspensionByAllId, deleteSuspension, getExportSuspension } from "../utils/peticionesApi";
import OptionComponentSolicitudes from "../components/OptionComponentSolicitudes";
import Button from "../components/Button";
import { useNavigate, useParams } from "react-router-dom";

const Suspensiones = () => {
    const { getToken, getRol, getId, getAlert } = useContext(NewContext);
    const [suspension, setSuspension] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [menuVisible, setMenuVisible] = useState(false);
    const [showModalSuspension, setShowModalSuspension] = useState(false);
    const [modalDeleteSuspension, setModalDeleteSuspension] = useState(false);
    const [selectedSuspensionId, setSelectedSuspensionId] = useState(null);
    const [selectedSuspension, setSelectedSuspension] = useState(null);
    const [request, setRequest] = useState([]);  // Declarar el estado para las suspensiones
    const [reasons, setReasons] = useState([]);  // Asegúrate de que las razones estén definidas
    const { id } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        if (getToken && getId) {
            listarSuspension();
        }
    }, [getToken, getId]);

    const closeMenu = () => setMenuVisible(false);
    const openModalSolicitud = () => {
        setSelectedSuspension(null);  // Cambié el nombre a 'Suspension'
        setShowModalSuspension(true);
    };

    const closeModalSolicitud = () => setShowModalSuspension(false);

    const listarSuspension = async () => {
        let response;
        try {

            response = await getSuspensionByAllId(id);

            if (response && response.data) {
                setRequest(response.data);
            } else {
                console.error("Error: La respuesta no contiene datos.");
            }
        } catch (error) {
            if (error.response) {
                console.error(`Error en la solicitud: ${error.response.status} - ${error.response.statusText}`);
            } else {
                console.error("Error al hacer la solicitud:", error.message);
            }
        }
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const filtradoSuspension = useMemo(() => {
        return request?.filter((r) =>
            r.reason?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, request]);

    const suspensionPorPagina = 15;
    const paginatedSuspension = useMemo(() => {
        const start = currentPage * suspensionPorPagina;
        const end = start + suspensionPorPagina;
        return filtradoSuspension.slice(start, end);
    }, [currentPage, filtradoSuspension]);

    const handleEditClick = (request) => {
        setSelectedSuspension(request);
        setShowModalSuspension(true);
    };

    const handleDeleteClick = (request) => {
        setSelectedSuspensionId(request.id);
        setModalDeleteSuspension(true);
    };

    const handleDeleteSuspension = async () => {
        try {
            if (selectedSuspensionId) {
                await deleteSuspension(selectedSuspensionId);
                getAlert({ msj: "Suspensión eliminada con éxito.", tipe: "succes" });
                setModalDeleteSuspension(false);
                listarSuspension();
            }
        } catch (error) {
            console.log(error);
            getAlert({ msj: "Error al eliminar suspensión.", tipe: "error" });

        }
    };

    const handleDownloadClick = async (suspension) => {
        setSelectedSuspensionId(suspension.id);

        try {
            await getExportSuspension(suspension.id);
            console.log('Export successful');
        } catch (error) {
            console.error('Export failed:', error);
        }
    };

    const TableRow = ({ request }) => {
        return (
            <tr>
                <td>{request.employee_name} {request.employee_last_name}</td>
                <td>{formatDate(request.suspension_date)}</td>
                <td>{request.applied_days}</td>
                <td>{request.reason}</td>
                <td>{request.status}</td>
                <td className="contenedor-item">
                    <FontAwesomeIcon icon={faPenToSquare} className="icon-edit" onClick={() => handleEditClick(request)} />
                    <FontAwesomeIcon icon={faTrash} className="icon-salida" onClick={() => handleDeleteClick(request)} />
                    <FontAwesomeIcon icon={faFileArrowDown} className="icon-File" onClick={() => handleDownloadClick(request)} />
                </td>
            </tr>
        );
    };

    return (
        <div className="contenedor-solicitudes">
            <button
                onClick={() => window.history.back()}
                className="buttonVolverObj"
            >
                <FontAwesomeIcon icon={faCircleLeft} />
                Volver
            </button>
            <p className="titulo">
                {showModalSuspension ? (selectedSuspension ? "Modificar Supensión  " : "Agregar Suspensión") : "Suspensiones"}
            </p>

            {showModalSuspension ? (
                <AgregarSuspensionModal
                    rol={getRol}
                    suspension={selectedSuspension}
                    closeModalSuspension={closeModalSolicitud}
                    handleSubmitSuspension={listarSuspension}
                />
            ) : (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <OptionComponentSolicitudes
                            type="text"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                            agregarFunction={openModalSolicitud}
                        />
                    </div>
                    <div className="data-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Empleado</th>
                                    <th>Fecha</th>
                                    <th>Días Aplicados</th>
                                    <th>Motivo</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedSuspension.length === 0 ? (
                                    <tr>
                                        <td colSpan="6">No se encontraron resultados.</td>
                                    </tr>
                                ) : (
                                    paginatedSuspension.map((request, index) => (
                                        <TableRow key={index} request={request} />
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(filtradoSuspension.length / suspensionPorPagina)}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </>
            )}

            {modalDeleteSuspension && (
                <div className="modalDelete">
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="iconoDelete"
                    />
                    <p>¿Estás seguro de eliminar esta suspensión?</p>
                    <div className="contenedorButonsDelete">
                        <Button
                            tipe="sinBg"
                            msj="Cancelar"
                            onClick={() => setModalDeleteSuspension(!modalDeleteSuspension)}
                        />
                        <Button msj="Confirmar" onClick={handleDeleteSuspension} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Suspensiones;

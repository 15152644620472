import React, { useContext } from "react";
import "../css/CardBirthday.css";
import { NewContext } from "../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";

const CardBirthday = ({ birthdays }) => {
  const { getCompany } = useContext(NewContext);
  const ulrPicturePersonal = "https://www.softnik.cloud/awsProfile/getProfile";

  if (!birthdays || birthdays.length === 0) {
    return <p>Sin cumpleaños este mes.</p>;
  }

  return (
    <div className="contenedorBirthdays">
      {birthdays.map((birth) => (
        <div key={birth.id} className="contenedorCardBirthday">
          <FontAwesomeIcon icon={faCakeCandles} className="iconBirthday" />
          <img
            src={`${ulrPicturePersonal}?uuid=${birth.id}&company=${getCompany}`}
            className="img-birthdays"
            alt={`${birth.id}`}
          />
          <p className="nombreUserBirthday">
            {birth.person.name} {birth.person.lastname}
          </p>
          {/* Ejemplo de mostrar solo día/mes */}
          <p className="birthdayUser">
            {birth.person.date_of_birth.split("-").slice(0, 2).join("-")}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CardBirthday;

import React, { useContext } from "react";
import { NewContext } from "../utils/ContextProvider";
import "../css/CardArrivals.css";
import defaultProfilePicture from '../image/Logo-jornada.svg';

const CardArrivals = ({ arrivals }) => {
  const { getCompany } = useContext(NewContext);
  const ulrPicturePersonal = "https://www.softnik.cloud/awsProfile/getProfile";

  return (
    <div className="contenedorTardanzas">
      {arrivals
        ? arrivals.map((empleado) => (
          <div key={empleado.id} className="contenedorCardTardanzas">
            <img
              src={`${ulrPicturePersonal}?uuid=${empleado.employeeId}&company=${getCompany}`}
              className="img-margin"
              alt={`${empleado.id}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultProfilePicture;
              }}
            />
            <div className="contenedorDatosTardanza">
              <p className="nombreTardanza">
                {empleado.name} {empleado.lastname}
              </p>
              <p className="txthoraTardanza">Cantidad de llegadas tardes:</p>
            </div>
            <p className="horaTardanza"> 1 dia</p>
          </div>
        ))
        : null}
    </div>
  );
};

export default CardArrivals;
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
    },
}));

const LoadingOverlay = ({ isLoading }) => {
    const classes = useStyles();

    if (!isLoading) return null;

    return (
        <div className={classes.overlay}>
            <CircularProgress />
        </div>
    );
};

export default LoadingOverlay;
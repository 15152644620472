import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import '../css/CardBirthday.css'

const MetricsHome = ({ asistencias = 0, ausencias = 0, tardanzas = 0, noFicharon = 0 }) => {
  const dataGris = 100 - (asistencias + ausencias + tardanzas + noFicharon)
  const data = {
    labels: ['Restante', 'Asistencias', 'Ausencias', 'Tardanzas', 'No ficharon'],
    datasets: [
      {
        data: [dataGris, asistencias, ausencias, tardanzas, noFicharon], // Porcentajes correspondientes
        backgroundColor: ['#ccc', '#8F3B8C', '#D47CD1', '#EC5B5B', '#00D2CA'], // Colores para cada tipo
        hoverBackgroundColor: ['#ccc', '#ccc', '#ccc', '#ccc'], // Colores cuando pasas el mouse por encima
        borderColor: ['#fff'], // Bordes blancos entre los segmentos
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Ocultamos la leyenda por defecto para personalizarla nosotros
      },
    },
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      {/* Gráfico de dona */}
      <div className='metricDonut'>
        <Doughnut data={data} options={options} />
      </div>
      {/* Leyenda personalizada */}
      <div>
        <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <li className='contenedorPorcentaje'>
            <span
              style={{
                display: 'inline-block',
                width: '1rem',
                height: '1rem',
                backgroundColor: '#4CAF50',
                marginRight: '0.5rem',
                borderRadius: '0.2rem'
              }}
            ></span>
            <div>
              <p className='subtituloPorcentaje'>{asistencias.toFixed(2)}%</p>
              <p className='tituloPorcentaje'>Asistencias</p>
            </div>
          </li>
          <li className='contenedorPorcentaje'>
            <span
              style={{
                display: 'inline-block',
                width: '1rem',
                height: '1rem',
                backgroundColor: '#8F3B8C',
                marginRight: '0.5rem',
                borderRadius: '0.2rem'
              }}
            ></span>
            <div className='contenedorFichaPorcentaje'>
              <p className='subtituloPorcentaje'>{ausencias.toFixed(2)}%</p>
              <p className='tituloPorcentaje'>Ausencias</p>
            </div>
          </li>
          <li className='contenedorPorcentaje'>
            <span
              style={{
                display: 'inline-block',
                width: '1rem',
                height: '1rem',
                backgroundColor: '#D47CD1',
                marginRight: '0.5rem',
                borderRadius: '0.2rem'
              }}
            ></span>
            <div className='contenedorFichaPorcentaje'>
              <p className='subtituloPorcentaje'>{tardanzas.toFixed(2)}%</p>
              <p className='tituloPorcentaje'>Tardanzas</p>
            </div>
          </li>
          <li className='contenedorPorcentaje'>
            <span
              style={{
                display: 'inline-block',
                width: '1rem',
                height: '1rem',
                backgroundColor: '#EC5B5B',
                marginRight: '0.5rem',
                borderRadius: '0.2rem'
              }}
            ></span>
            <div className='contenedorFichaPorcentaje'>
              <p className='subtituloPorcentaje'>{noFicharon.toFixed(2)}%</p>
              <p className='tituloPorcentaje'>No ficharon</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MetricsHome
